const colours = {
  purple: "#4b0a5f",
  lightPurple: "#941cb7",
  pink: "#c600d5",
  darkPurple: "#1e0421",
  red: "#7a1535",
  white: "#fff"
};

export default colours;
