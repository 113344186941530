import styled from "styled-components";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import Countdown from "react-countdown";

import { shortenAddress } from "../../candy-machine";
import { Container, Panel, WalletHeading } from "../shared/styled";
import colours from "../shared/colours";

import headerBackground from "../../images/header-background.jpg";
import logo from "../../images/logo.png";

const StyledHeader = styled.header`
  background: url(${headerBackground}) center top / cover no-repeat;
  height: 100vh;
  min-height: 600px;
  max-height: 1165px;
  padding: 30px 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 1024px) {
    padding-bottom: 75px;
  }

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 820px;
  margin-bottom: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);

  img {
    max-height: 100%;
  }

  @media (max-width: 1024px) {
    position: static;
    transform: none;
  }

  @media (max-width: 767px) {
    width: 90%;
    position: static;
    transform: none;
  }

  @media (max-height: 870px) {
    margin-top: 0;

    img {
      max-height: 800px;
    }
  }

  @media (max-height: 767px) {
    img {
      max-height: 700px;
    }
  }

  @media (max-width: 600px) {
    width: 110%;
  }

  @media (max-width: 480px) {
    width: 130%;
    position: static;
    transform: none;
  }
`;

const ConnectWallet = styled(WalletDialogButton)`
  &[type="button"] {
    background: ${colours.purple};
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 1;
    cursor: pointer;
    font-family: "AlbertusExtraBold";
    font-size: 2rem;
    line-height: normal;
    text-transform: uppercase;
    color: ${colours.white};

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 20px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: background-color 0.3s ease;
    }

    &:hover,
    &:focus {
      background: ${colours.purple};
      box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);

      &::before {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    @media (max-width: 767px) {
      right: 20px;
    }

    @media (max-width: 400px) {
      justify-content: center;
      width: calc(100% - 40px);
      font-size: 1.8rem;
      text-align: center;
    }
  }
`;

const Wallet = styled(Panel)`
  padding: 15px;
  border: 1px solid ${colours.white};
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  margin: 0 0 auto auto;
  font-size: 1.2rem;

  &::before {
    background-position: initial;
    background-size: 60px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
  }

  li {
    margin-bottom: 5px;
  }

  @media (max-width: 1024px) {
    margin: 0;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CountdownWrap = styled.div`
  background: ${colours.purple};
  padding: 25px 40px;
  border: 1px solid ${colours.white};
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  margin-top: auto;
  z-index: 1;
  font-family: "AlbertusExtraBold";
  font-size: 3.5rem;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  color: ${colours.white};

  @media (max-width: 1400px) {
    font-size: 3rem;
  }

  @media (max-width: 1024px) {
    padding: 20px 30px;
    margin-top: 20px;
    font-size: 2.5rem;
  }

  @media (max-width: 767px) {
    padding: 15px 20px;
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 1.8rem;
  }
`;

type Props = {
  wallet: AnchorWallet | undefined;
  balance: number | undefined;
  itemsAvailable: number;
  itemsRedeemed: number;
  itemsRemaining: number;
  isActive: boolean;
  startDate: Date;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  renderCounter: ({
    days,
    hours,
    minutes,
    seconds
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => React.ReactElement;
};

const Header = ({
  wallet,
  balance,
  itemsAvailable,
  itemsRedeemed,
  itemsRemaining,
  isActive,
  startDate,
  setIsActive,
  renderCounter
}: Props) => {
  return (
    <StyledHeader>
      <Container>
        <Logo>
          <img
            src={logo}
            alt="Dragons Watch Heroes"
            data-aos="zoom-out"
            data-aos-delay="300"
          />
        </Logo>

        {wallet ? (
          <Wallet>
            <WalletHeading>Master Edition Sale</WalletHeading>
            <ul>
              <li>
                Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
              </li>
              <li>Balance: {(balance || 0).toLocaleString()} SOL</li>
              <li>Total Available: {itemsAvailable}</li>
              <li>Redeemed: {itemsRedeemed}</li>
              <li>Remaining: {itemsRemaining}</li>
            </ul>
          </Wallet>
        ) : (
          <ConnectWallet type="button">Connect Wallet</ConnectWallet>
        )}

        {!isActive && (
          <CountdownWrap>
            <Countdown
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
          </CountdownWrap>
        )}
      </Container>
    </StyledHeader>
  );
};

export default Header;
