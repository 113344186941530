import styled from "styled-components";

const VideoContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 30px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  margin-bottom: 80px;
  position: relative;
  overflow: hidden;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @media (max-width: 480px) {
    margin-bottom: 60px;
  }
`;

const Video = () => (
  <VideoContainer data-aos="fade-up">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/rI8tOOP33xw?autoplay=0&rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Dragons Watch Teaser"
    />
  </VideoContainer>
);

export default Video;
