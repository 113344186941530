import {
  biBladeFyaz,
  biceratopsRaptor,
  blazefuryXena,
  bossBitchBurn,
  brokenDawning,
  cobwebsJada,
  contagiousCankerclutch,
  crookedThornflash,
  cutlassPike,
  daringDorio,
  davyJones,
  deadlyDemia,
  deludedDesdemona,
  extraPocketPolly,
  fatalSiren,
  FayetteLimeMist,
  fireJugglerPapa,
  frillyMcgilly,
  frostyWelcome,
  gary,
  geoffreyTheAmazing,
  glommgarde,
  gordonBennett,
  ioakin,
  kamohoalii,
  kelp,
  kitty,
  labyrinthOfTrials,
  lagoonDebbie,
  lemonpithSyrupJellycat,
  madnesspeak,
  magentaHooter,
  neoGnathan,
  pegasus,
  pilferingThornflash,
  princessOfDarkness,
  queenBeeAtch,
  queenSerpentia,
  redCraven,
  relf,
  reveredLordTreadway,
  rottenKeep,
  rubyPhantasm,
  salemTheEnchanter,
  sapsuckerBat,
  serenity,
  sorcererShaju,
  spikeyHelmut,
  spiritswordPurity,
  spores,
  stumpy,
  terribleIdea,
  thanatos,
  thievingBstard,
  warriorFelis,
  wolfSpectre
} from "../../images/cards/imports";

export type Card = {
  url: string;
  name: string;
  element: "dark" | "light" | "earth" | "fire" | "water";
};

const cards: Card[] = [
  { url: biBladeFyaz, name: "Bi-Blade Fyaz", element: "light" },
  { url: biceratopsRaptor, name: "Biceratops Raptor", element: "fire" },
  { url: blazefuryXena, name: "Blazefury Xena", element: "fire" },
  { url: bossBitchBurn, name: "Boss Bitch Burn", element: "fire" },
  { url: brokenDawning, name: "Broken Dawning", element: "light" },
  { url: cobwebsJada, name: "Cobwebs Jada", element: "earth" },
  {
    url: contagiousCankerclutch,
    name: "Contagious Cankerclutch",
    element: "earth"
  },
  { url: crookedThornflash, name: "Crooked Thornflash", element: "water" },
  { url: cutlassPike, name: "Cutlass Pike", element: "water" },
  { url: daringDorio, name: "Daring D'Orio", element: "light" },
  { url: davyJones, name: "Davy Jones", element: "water" },
  { url: deadlyDemia, name: "Deadly Demia", element: "dark" },
  { url: deludedDesdemona, name: "Deluded Desdemona", element: "dark" },
  { url: extraPocketPolly, name: "Extra Pocket Polly", element: "light" },
  { url: fatalSiren, name: "Fatal Siren", element: "water" },
  { url: FayetteLimeMist, name: "Fayette Lime-Mist", element: "earth" },
  { url: fireJugglerPapa, name: "Fire Juggler Papa", element: "fire" },
  { url: frillyMcgilly, name: "Frilly McGilly", element: "water" },
  { url: frostyWelcome, name: "Frosty Welcome", element: "water" },
  { url: gary, name: "Gary", element: "water" },
  { url: geoffreyTheAmazing, name: "Geoffrey The Amazing", element: "light" },
  { url: glommgarde, name: "Glommgarde", element: "earth" },
  { url: gordonBennett, name: "Gordon Bennett", element: "light" },
  { url: ioakin, name: "Ioakin", element: "dark" },
  { url: kamohoalii, name: "Kamohoalii", element: "water" },
  { url: kelp, name: "Kelp", element: "earth" },
  { url: kitty, name: "Kitty", element: "light" },
  { url: labyrinthOfTrials, name: "Labyrinth Of Trials", element: "fire" },
  { url: lagoonDebbie, name: "Lagoon Debbie", element: "dark" },
  {
    url: lemonpithSyrupJellycat,
    name: "Lemonpith Syrup Jellycat",
    element: "light"
  },
  { url: madnesspeak, name: "Madnesspeak", element: "water" },
  { url: magentaHooter, name: "Magenta Hooter", element: "dark" },
  { url: neoGnathan, name: "Neo Gnathan", element: "water" },
  { url: pegasus, name: "Pegasus", element: "light" },
  { url: pilferingThornflash, name: "Pilfering Thornflash", element: "water" },
  { url: princessOfDarkness, name: "Princess Of Darkness", element: "dark" },
  { url: queenBeeAtch, name: "Queen Bee-Atch", element: "dark" },
  { url: queenSerpentia, name: "Queen Serpentia", element: "earth" },
  { url: redCraven, name: "Red Craven", element: "fire" },
  { url: relf, name: "Relf", element: "dark" },
  { url: reveredLordTreadway, name: "Revered Lord Treadway", element: "earth" },
  { url: rottenKeep, name: "Rotton Keep", element: "dark" },
  { url: rubyPhantasm, name: "Ruby Phantasm", element: "fire" },
  { url: salemTheEnchanter, name: "Salem The Enchanter", element: "earth" },
  { url: sapsuckerBat, name: "Sapsucker Bat", element: "earth" },
  { url: serenity, name: "Serenity", element: "water" },
  { url: sorcererShaju, name: "Sorcerer Shaju", element: "dark" },
  { url: spikeyHelmut, name: "Spikey Helmut", element: "fire" },
  { url: spiritswordPurity, name: "Spritsword Purity", element: "earth" },
  { url: spores, name: "Spores", element: "dark" },
  { url: stumpy, name: "Stumpy", element: "dark" },
  { url: terribleIdea, name: "Terrible Idea", element: "fire" },
  { url: thanatos, name: "Thanatos", element: "dark" },
  { url: thievingBstard, name: "Thieving B'stard", element: "earth" },
  { url: warriorFelis, name: "Warrior Felis", element: "light" },
  { url: wolfSpectre, name: "Wolf Spectre", element: "earth" }
];

// Fisher-Yates shuffle
const shuffle = (array: Card[]): Card[] => {
  const shuffled = [...array];

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
};

const getRandomCards = ({
  qty,
  balanced,
  element
}: {
  qty: number;
  balanced?: boolean;
  element?: "dark" | "fire" | "water" | "earth" | "light";
}): Card[] => {
  const cardsToShuffle = element
    ? cards.filter(card => card.element === element)
    : cards;
  const shuffled = shuffle(cardsToShuffle);
  const cardsPerElement = balanced ? Math.ceil(qty / 5) : null;

  return shuffled.reduce((prev: Card[], curr: Card): Card[] => {
    if (prev.length === qty) {
      return prev;
    }

    if (
      cardsPerElement &&
      prev.filter(p => p.element === curr.element).length === cardsPerElement
    ) {
      return prev;
    }

    return prev.concat(curr);
  }, []);
};

export default getRandomCards;
