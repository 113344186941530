import styled from "styled-components";
import { Link } from "react-router-dom";

import { Container, ButtonStyles } from "../shared/styled";
import colours from "../shared/colours";

import logo from "../../images/logo.png";

const StyledHeader = styled.header`
  background: ${colours.purple};
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  padding: 30px 0;
  position: relative;
`;

const Logo = styled(Link)`
  width: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 480px) {
    left: auto;
    right: 0;
    transform: translate(0, -50%);
  }
`;

const Back = styled(Link)`
  ${ButtonStyles}
  align-self: flex-start;
  background: ${colours.lightPurple};
  font-size: 1.8rem;

  &:hover,
  &:focus {
    background: ${colours.lightPurple};
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <Logo to="/">
          <img src={logo} alt="Dragon's Watch Heroes" />
        </Logo>

        <Back to="/">Home</Back>
      </Container>
    </StyledHeader>
  );
};

export default Header;
