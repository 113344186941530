import styled from "styled-components";

import buttonDigitalEyes from "../../images/button-digital-eyes.png";
import buttonFractal from "../../images/button-fractal.png";
import buttonFtx from "../../images/button-ftx.png";
import buttonMagicEden from "../../images/button-magic-eden.png";
import buttonSolsea from "../../images/button-solsea.png";

type Marketplace = {
  name: string;
  image: string;
  url: string;
};

const Section = styled.section`
  margin-top: 5rem;
`;

const Heading = styled.h2`
  margin-bottom: 0.6em;
  text-align: center;
`;

const Buttons = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Button = styled.a`
  flex: 1 1 calc(50% - 15px);
  max-width: 650px;
  min-width: 400px;
  text-align: center;

  img {
    display: block;
  }

  @media (max-width: 480px) {
    flex-basis: 100%;
    min-width: 0;
  }
`;

const marketplaces: Marketplace[] = [
  {
    name: "Magic Eden",
    image: buttonMagicEden,
    url: "https://magiceden.io/marketplace/dragons_watch_heroes"
  },
  {
    name: "FTX.US",
    image: buttonFtx,
    url: "https://ftx.us/nfts/issuer/Dragons%20Watch%20Heroes/25/1"
  },
  {
    name: "Digital Eyes",
    image: buttonDigitalEyes,
    url: "https://digitaleyes.market/collections/Dragons%20Watch%20Heroes"
  },
  {
    name: "SolSea",
    image: buttonSolsea,
    url: "https://solsea.io/collection/61983551932c5a0b7b42cf4d"
  },
  {
    name: "Fractal",
    image: buttonFractal,
    url: "https://www.fractal.is/dragonswatchheroes"
  }
];

const Marketplaces = () => {
  return (
    <Section data-aos="fade-up" id="marketplaces">
      <Heading>Also available on these Marketplaces</Heading>

      <Buttons>
        {marketplaces.map((marketplace, index) => (
          <Button
            key={index}
            href={marketplace.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={marketplace.image}
              alt={marketplace.name}
              title={marketplace.name}
              data-aos="zoom-out"
              data-aos-delay={index * 200}
              data-aos-anchor="#marketplaces"
            />
          </Button>
        ))}
      </Buttons>
    </Section>
  );
};

export default Marketplaces;
