import * as React from "react";
import styled from "styled-components";

import colours from "../shared/colours";
import loading from "../../images/loading.gif";

const Cover = styled.div`
  background: ${colours.darkPurple};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 320px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease;

  &[data-loaded="true"] {
    opacity: 0;
    visibility: hidden;
  }
`;

const Spinner = styled.div`
  background: url(${loading}) center / contain no-repeat;
  width: 5rem;
  padding-bottom: 72.04%;
`;

const Loading = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    window.addEventListener("load", handleLoad);

    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <Cover data-loaded={isLoaded}>
      <Spinner />
    </Cover>
  );
};

export default Loading;
