import styled from "styled-components";
import { Link } from "react-router-dom";

import { Container, Panel, ButtonStyles } from "../shared/styled";

import harpy from "../../images/harpy.png";

const WelcomePanel = styled(Panel)`
  display: flex;
  margin-bottom: 80px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: 40px 0 40px;
  }
`;

const Harpy = styled.div`
  width: 420px;

  img {
    display: block;
    margin: -100px 0 -60px -100px;
  }

  @media (max-width: 1700px) {
    img {
      margin-left: -60px;
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 340px;

    img {
      margin: 0 0 0 -60px;
    }
  }

  @media (max-width: 1024px) {
    img {
      margin: -140px 0 20px;
    }
  }
`;

const WelcomeText = styled.div`
  flex: 1;
  width: 100%;
  margin-left: -50px;

  @media (max-width: 1700px) {
    margin-left: -30px;
  }

  @media (max-width: 1024px) {
    margin: 0;
    text-align: center;
  }
`;

const PreHeading = styled.span`
  display: block;
  margin-bottom: 10px;
  font-family: "AlbertusExtraBold";
  font-size: 4rem;
  text-transform: uppercase;

  @media (max-width: 480px) {
    font-size: 3.8rem;
  }

  @media (max-width: 400px) {
    font-size: 3.4rem;
  }
`;

const Heading = styled.h1`
  margin-bottom: 30px;
  font-family: "Calibri", "Arial", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
`;

const Buttons = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 80px;

  @media (max-width: 1024px) {
    margin: 50px auto 80px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 30px auto 60px;
  }
`;

const Library = styled(Link)`
  ${ButtonStyles}
  width: 260px;
  padding: 20px 30px;
  margin-right: 20px;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0 0 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 15px 20px;
  }
`;

/*
const Whitepaper = styled.a`
  ${ButtonStyles}
  width: 260px;
  padding: 20px 30px;
  text-align: center;

  @media (max-width: 480px) {
    width: 100%;
    padding: 15px 20px;
  }
`;
*/
const Welcome = () => {
  return (
    <>
      <WelcomePanel as="section" data-aos="fade-up">
        <Harpy
          data-aos={
            window.matchMedia("(max-width: 1024px)").matches
              ? null
              : "fade-right"
          }
          data-aos-delay="300"
        >
          <img src={harpy} alt="Harpy" />
        </Harpy>

        <WelcomeText>
          <PreHeading>Welcome</PreHeading>
          <Heading>Welcome to Dragon's Watch Heroes!</Heading>

          <p>
            Dragons Watch Heroes is a brand new digital trading card game
            featuring 854 unique heroes! The Heroes have been lovingly
            handcrafted by a team of professional game artists over the course
            of three years. All proceeds from the sale will go to fund the
            development of the game.
          </p>

          <p>
            The NFTs will have in-game utility and you can also earn rewards by
            completing sets.
          </p>
        </WelcomeText>
      </WelcomePanel>

      <Buttons>
        <Library to="/library" data-aos="zoom-out">
          Library
        </Library>

      </Buttons>
    </>
  );
};

/*
        <Whitepaper
          href="https://drive.google.com/file/d/1HinI4psxlrrdtf8oXiG26EXX56CX88FC/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          data-aos="zoom-out"
          data-aos-delay="100"
        >
          Whitepaper
        </Whitepaper>
*/
export default Welcome;
