import styled, { keyframes } from "styled-components";

import {
  back,
  backGlowDark,
  backGlowEarth,
  backGlowLight,
  cobwebsJada,
  glommgarde,
  kamohoalii,
  neoGnathan,
  pegasus,
  princessOfDarkness,
  redCraven,
  rubyPhantasm,
  stumpy
} from "../../images/cards/imports";
import { Sparkle } from "../shared/styled";

const cardFrontFlip = keyframes`
  0% {
    transform: scaleX(0);
    visibility: hidden;
  }

  100% {
    transform: scaleX(1);
    visibility: visible;
  }
`;

const cardBackFlip = keyframes`
  0% {
    transform: scaleX(1);
    visibility: visible;
  }

  100% {
    transform: scaleX(0);
    visibility: hidden;
  }
`;

const RaritySection = styled.section`
  padding: 0 7.5%;

  p {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`;

const Main = styled.div`
  margin-bottom: 80px;

  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;

  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;

const Card = styled.div`
  text-align: center;

  img {
    display: block;
    width: 100%;
    will-change: transform;
  }

  span {
    display: block;
    margin-top: -10px;
  }

  @media (max-width: 1024px) {
    span {
      margin-top: -5px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 40px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Images = styled.div`
  position: relative;
`;

const CardBack = styled.img`
  position: absolute;
  top: 0;
  left: 0;

  .aos-animate & {
    animation: ${cardBackFlip} 0.2s linear forwards;
  }
`;

const CardFront = styled.img`
  .aos-animate & {
    animation: ${cardFrontFlip} 0.2s linear backwards;
  }
`;

const UltraRare = styled(Card)`
  position: relative;

  ${Sparkle}:nth-child(1) {
    width: 10%;
    height: 10%;
    top: 11.9%;
    right: 0.5%;
    animation-delay: 1s;
  }

  ${Sparkle}:nth-child(2) {
    width: 20%;
    height: 20%;
    top: 64.5%;
    right: -7%;
    animation-delay: 1.5s;
  }

  ${Sparkle}:nth-child(3) {
    width: 7%;
    height: 7%;
    top: 77%;
    right: -9%;
    animation-delay: 2s;
  }

  ${Sparkle}:nth-child(4) {
    width: 23%;
    height: 23%;
    top: 80%;
    right: -5%;
    animation-delay: 2.5s;
  }

  ${Sparkle}:nth-child(5) {
    width: 20%;
    height: 20%;
    top: 73%;
    left: -7%;
    animation-delay: 3s;
  }

  ${Sparkle}:nth-child(6) {
    width: 7%;
    height: 7%;
    top: 85%;
    left: -0.4%;
    animation-delay: 3.5s;
  }
`;

const Boss = styled(Card)`
  position: relative;

  span {
    display: block;
    margin-top: -5px;
  }

  ${Sparkle}:nth-child(1) {
    width: 23%;
    height: 23%;
    top: 79%;
    left: -15%;
    animation-delay: 1s;
  }

  ${Sparkle}:nth-child(2) {
    width: 7%;
    height: 7%;
    top: 93.3%;
    left: -6.5%;
    animation-delay: 1.75s;
  }

  ${Sparkle}:nth-child(3) {
    width: 20%;
    height: 20%;
    top: 91%;
    left: -3%;
    animation-delay: 2.2s;
  }

  ${Sparkle}:nth-child(4) {
    width: 7%;
    height: 7%;
    top: 92%;
    right: 3.8%;
    animation-delay: 1.5s;
  }
`;

const Dragon = styled(Card)`
  span {
    margin-top: 0;
  }
`;

const GoldenGod = styled(Card)`
  span {
    margin-top: 0;
  }
`;

const Rarity = () => {
  return (
    <RaritySection>
      <h2 data-aos="fade-up">Rarity</h2>

      <Main>
        <p data-aos="fade-up">
          The main heroes fall into 6 main rarity tiers. In these first edition
          sales the odds of acquiring 4&#10029;+ heroes are greatly increased.
        </p>

        <Cards data-aos="fade-up">
          <Card>
            <Images>
              <CardFront
                src={rubyPhantasm}
                alt="Ruby Phantasm"
                style={{ animationDelay: "0.2s" }}
              />
              <CardBack src={back} alt="Card back" />
            </Images>
            <span>
              1&#10029; Common - 16.15%
              <br />
              x10 of each
            </span>
          </Card>
          <Card>
            <Images>
              <CardFront
                src={kamohoalii}
                alt="Kamohoalii"
                style={{ animationDelay: "0.4s" }}
              />
              <CardBack
                src={back}
                alt="Card back"
                style={{ animationDelay: "0.2s" }}
              />
            </Images>
            <span>
              2&#10029; Uncommon - 6.36%
              <br />
              x9 of each
            </span>
          </Card>
          <Card>
            <Images>
              <CardFront
                src={stumpy}
                alt="Stumpy"
                style={{ animationDelay: "0.6s" }}
              />
              <CardBack
                src={back}
                alt="Card back"
                style={{ animationDelay: "0.4s" }}
              />
            </Images>
            <span>
              3&#10029; Lesser spotted - 12.32%
              <br />
              x8 of each
            </span>
          </Card>
          <Card>
            <Images>
              <CardFront
                src={neoGnathan}
                alt="Neo Gnathan"
                style={{ animationDelay: "0.8s" }}
              />
              <CardBack
                src={back}
                alt="Card back"
                style={{ animationDelay: "0.6s" }}
              />
            </Images>
            <span>
              4&#10029; Rare - 31.98%
              <br />
              x7 of each
            </span>
          </Card>
          <Card>
            <Images>
              <CardFront
                src={redCraven}
                alt="Red Craven"
                style={{ animationDelay: "1s" }}
              />
              <CardBack
                src={back}
                alt="Card back"
                style={{ animationDelay: "0.8s" }}
              />
            </Images>
            <span>
              5&#10029; Super Rare - 22.34%
              <br />
              x5 of each
            </span>
          </Card>
          <UltraRare>
            <Images>
              <CardFront
                src={cobwebsJada}
                alt="Cobwebs Jada"
                style={{ animationDelay: "1.2s" }}
              />
              <CardBack
                src={back}
                alt="Card back"
                style={{ animationDelay: "1s" }}
              />
            </Images>
            <span>
              6&#10029; Ultra Rare - 9.92%
              <br />
              x3 of each
            </span>
            <div>
              <Sparkle />
              <Sparkle />
              <Sparkle />
              <Sparkle />
              <Sparkle />
              <Sparkle />
            </div>
          </UltraRare>
        </Cards>
      </Main>

      <p>
        There are 14 world regions in the game, each region has a Boss, a Dragon
        and a Golden God. There’s only ONE of each. There are 4 additional
        Golden Gods that relate to cross-region sets.
      </p>

      <Cards data-aos="fade-up">
        <Boss>
          <Images>
            <CardFront
              src={princessOfDarkness}
              alt="Princess Of Darkness"
              style={{ animationDelay: "0.2s" }}
            />
            <CardBack src={backGlowDark} alt="Card back" />
          </Images>
          <span>Boss x11 - 0.28%</span>
          <div>
            <Sparkle />
            <Sparkle />
            <Sparkle />
            <Sparkle />
          </div>
        </Boss>
        <Dragon>
          <Images>
            <CardFront
              src={glommgarde}
              alt="Glommgarde"
              style={{ animationDelay: "0.4s" }}
            />
            <CardBack
              src={backGlowEarth}
              alt="Card back"
              style={{ animationDelay: "0.2s" }}
            />
          </Images>
          <span>Dragon x11 - 0.28%</span>
        </Dragon>
        <GoldenGod>
          <Images>
            <CardFront
              src={pegasus}
              alt="Pegasus"
              style={{ animationDelay: "0.6s" }}
            />
            <CardBack
              src={backGlowLight}
              alt="Card back"
              style={{ animationDelay: "0.4s" }}
            />
          </Images>
          <span>Golden God x15 - 0.38%</span>
        </GoldenGod>
      </Cards>
    </RaritySection>
  );
};

export default Rarity;
