import styled from "styled-components";

import { Container } from "../components/shared/styled";
import colours from "../components/shared/colours";

import discordLogo from "../images/discord.png";
import twitterLogo from "../images/twitter.png";
import mediumLogo from "../images/medium.png";

const StyledFooter = styled.footer`
  background: ${colours.red};
  padding: 60px 0;
  text-align: center;

  @media (max-width: 480px) {
    padding: 40px 0;
  }

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.6em;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 3.4rem;
  }

  @media (max-width: 400px) {
    font-size: 2.5rem;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;

const Discord = styled.a`
  margin-left: 18px;

  img {
    width: 45px;
  }

  @media (max-width: 480px) {
    margin-left: 15px;

    img {
      width: 35px;
    }
  }
`;

const Twitter = styled.a`
  margin: 0 36px 0 64px;

  img {
    width: 55px;
  }

  @media (max-width: 480px) {
    img {
      width: 40px;
    }
  }
`;

const Medium = styled.a`
  img {
    width: 100px;
  }

  @media (max-width: 480px) {
    img {
      width: 80px;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Heading>Join Our Community</Heading>

        <Icons>
          <Discord
            href="https://discord.gg/p9AFbnTrDs"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discordLogo} alt="Discord" />
          </Discord>

          <Twitter
            href="https://twitter.com/DragonsWatchNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterLogo} alt="Twitter" />
          </Twitter>

          <Medium
            href="https://medium.com/@DragonsWatchNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={mediumLogo} alt="Medium" />
          </Medium>
        </Icons>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
