import { heroes } from "../../heroes.json";

type Hero =
  | {
      unitID: string;
      family: string;
      evo: string;
      variant: string;
      name: string;
      element: string;
      rarity: number;
      set: string;
    }
  | {
      unitID: string;
      family: string;
      evo: string;
      variant: string;
      name: string;
      element: string;
      rarity: number;
      set?: undefined;
    }
  | {
      unitID: string;
      set: string;
      family: string;
      element: string;
      name: string;
      rarity: number;
      variant: string;
      evo: number;
    }
  | {
      unitID: string;
      set: string;
      element: string;
      name: string;
      rarity: number;
      variant: string;
      evo: number;
      family?: undefined;
    };

// Fisher-Yates shuffle
const getShuffledNfts = (): Hero[] => {
  const shuffled = [...heroes];

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
};

export default getShuffledNfts;
