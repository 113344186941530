import * as React from "react";
import styled from "styled-components";

import Header from "./components/Library/Header";
import Footer from "./template/Footer";
import { Wrapper, Main, Container } from "./components/shared/styled";
import getShuffledHeroes from "./components/shared/getShuffledNfts";

const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  img {
    display: block;
  }

  @media (max-width: 350px) {
    img {
      width: calc(33.333333% - 10px);
    }
  }
`;

const Library = () => {
  const shuffledHeroes = React.useMemo(() => getShuffledHeroes(), []);

  return (
    <Wrapper>
      <Header />
      <Main>
        <Container>
          <Grid>
            {shuffledHeroes.map(hero => (
              <img
                key={hero.unitID}
                src={`${process.env.PUBLIC_URL}/nfts/thumbnails/${hero.element}/${hero.unitID}.jpg`}
                title={hero.name}
                alt={hero.name}
              />
            ))}
          </Grid>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default Library;
