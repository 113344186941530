import styled, { css, keyframes } from "styled-components";

import colours from "./colours";

import emblem from "../../images/emblem.png";
import sparkle from "../../images/sparkle.png";

const sparkleFade = keyframes`
0%,
70% {
  opacity: 0;
}

80%,
90% {
  opacity: 1;
}

100% {
  opacity: 0;
}
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  max-width: 2000px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1560px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const Main = styled.main`
  background: ${colours.darkPurple};
  padding: 100px 0;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 80px 0;
  }
`;

export const Panel = styled.div`
  background: ${colours.purple};
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: url(${emblem}) -50px -10px / 120px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.4;
  }

  @media (max-width: 767px) {
    padding: 30px;
  }
`;

export const Divider = styled.div`
  background: ${colours.red};
  height: 5px;
  margin: 5rem 0;
`;

export const CenteredHeading = styled.h2`
  text-align: center;
`;

export const Sparkle = styled.div`
  background: url(${sparkle}) center / 100% no-repeat;
  position: absolute;
  animation: ${sparkleFade} 3s linear infinite backwards;
`;

export const ButtonStyles = css`
  display: inline-block;
  background: ${colours.purple};
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: "AlbertusExtraBold";
  font-size: 2rem;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colours.white};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background-color 0.3s ease;
  }

  &:hover,
  &:focus {
    background: ${colours.purple};
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
    text-decoration: none;

    &::before {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 480px) {
    padding: 10px 15px;
    border-radius: 15px;
    font-size: 1.8rem;

    &::before {
      border-radius: 15px;
    }
  }

  @media (max-width: 400px) {
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 1.6rem;

    &::before {
      border-radius: 12px;
    }
  }
`;

export const WalletHeading = styled.p`
  margin-top: 0;
  margin-bottom: 0.4em;
  font-family: "AlbertusExtraBold";
  font-size: 2rem;
`;
