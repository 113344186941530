import { useEffect, useState, useMemo } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress
} from "./candy-machine";

import Header from "./components/Genesis/Header";
import Footer from "./template/Footer";
import {
  Wrapper,
  Container,
  Main,
  Panel,
  WalletHeading
} from "./components/shared/styled";
import CardCarousel from "./components/shared/CardCarousel";
import GenesisSale from "./components/Genesis/GenesisSale";
import getRandomCards from "./components/shared/getRandomCards";
import colours from "./components/shared/colours";

export interface HomeProps {
  connection: anchor.web3.Connection;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Wallet = styled(Panel)`
  padding: 15px;
  border: 1px solid ${colours.white};
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  margin: -20px 0 40px;
  font-size: 1.2rem;

  &::before {
    background-position: initial;
    background-size: 60px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const Genesis = (props: HomeProps) => {
  const config = new anchor.web3.PublicKey(
    process.env.REACT_APP_DARK_CANDY_MACHINE_CONFIG!
  );

  const candyMachineId = useMemo<anchor.web3.PublicKey>(
    () =>
      new anchor.web3.PublicKey(process.env.REACT_APP_DARK_CANDY_MACHINE_ID!),
    []
  );

  const startDateSeed = parseInt(
    process.env.REACT_APP_DARK_CANDY_START_DATE!,
    10
  );

  const url = new URL(window.location.href);
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(
    url.searchParams.has("earlybird") ? true : false
  ); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined
  });

  const startDate = new Date(startDateSeed * 1000);

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const cards = useMemo(() => getRandomCards({ qty: 10, element: "dark" }), []);

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        /* goLiveDate, */
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      //setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success"
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error"
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error"
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    candyMachineId,
    props.connection
  ]);

  return (
    <Wrapper>
      <Header wallet={wallet} />
      <Main>
        <Container>
          {wallet && (
            <Wallet>
              <WalletHeading>Genesis Sale</WalletHeading>

              <ul>
                <li>
                  Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
                </li>
                <li>Balance: {(balance || 0).toLocaleString()} SOL</li>
                <li>Total Available: {itemsAvailable}</li>
                <li>Redeemed: {itemsRedeemed}</li>
                <li>Remaining: {itemsRemaining}</li>
              </ul>
            </Wallet>
          )}
          <CardCarousel cards={cards} />
          <GenesisSale
            wallet={wallet}
            isSoldOut={isSoldOut}
            isMinting={isMinting}
            onMint={onMint}
            isActive={isActive}
            startDate={startDate}
            setIsActive={setIsActive}
            renderCounter={renderCounter}
          />
        </Container>
      </Main>
      <Footer />

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

/*
<Divider />
<Team />
*/

const renderCounter = ({
  days,
  hours,
  minutes,
  seconds
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}): React.ReactElement => {
  return (
    <span>
      {days} days, {hours} hrs, {minutes} mins, {seconds} secs
    </span>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default Genesis;
