import { useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Home";
import Library from "./Library";
import Genesis from "./Genesis";
import Loading from "./components/shared/Loading";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark"
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start"
      }
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px"
      },
      startIcon: {
        marginRight: 8
      },
      endIcon: {
        marginLeft: 8
      }
    }
  }
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <>
      <Loading />
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Home
                        connection={connection}
                        treasury={treasury}
                        txTimeout={txTimeout}
                      />
                    }
                  />

                  <Route path="library" element={<Library />} />

                  <Route
                    path="genesis"
                    element={
                      <Genesis
                        connection={connection}
                        treasury={treasury}
                        txTimeout={txTimeout}
                      />
                    }
                  />
                </Routes>
              </Router>
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
