import * as React from "react";
import styled from "styled-components";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Card } from "./getRandomCards";

SwiperCore.use([Autoplay]);

const SwiperContainer = styled.div`
  margin-bottom: 80px;

  .swiper-container {
    padding: 20px 0;
  }

  .swiper-slide {
    z-index: 1;

    &::before {
      content: "";
      box-shadow: 0px 3px 12px 5px rgba(0, 0, 0, 0.9);
      position: absolute;
      top: 4.4%;
      bottom: 4.5%;
      left: 6.1%;
      right: 6.1%;
      z-index: -1;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
`;

const CardCarousel = ({ cards }: { cards: Card[] }) => {
  return (
    <SwiperContainer
      data-aos={
        window.matchMedia("(max-width: 599px)").matches
          ? "fade-up"
          : "fade-left"
      }
    >
      <Swiper
        speed={500}
        spaceBetween={0}
        slidesPerView={1.25}
        autoplay
        loop
        centeredSlides
        breakpoints={{
          1400: {
            slidesPerView: 3.75
          },
          1024: {
            slidesPerView: 3.25
          },
          767: {
            slidesPerView: 2
          },
          480: {
            slidesPerView: 1.5
          }
        }}
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index}>
            <img src={card.url} alt={card.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default CardCarousel;
