import * as React from "react";
import styled, { css } from "styled-components";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import Countdown from "react-countdown";
import { Button, CircularProgress } from "@material-ui/core";

import { Panel, Sparkle } from "../shared/styled";
import colours from "../shared/colours";

// import cardPacks from "../../images/card-packs.png";
import masterEditionCardPacks from "../../images/master_edition_card_packs.png";
import solanaIcon from "../../images/solana_icon_white.svg";

const PreSalePanel = styled(Panel)`
  position: relative;

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PreSaleText = styled.div`
  width: 50%;
  text-align: center;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const ButtonStyles = css`
  background: ${colours.pink};
  padding: 20px 30px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: "AlbertusExtraBold";
  font-size: 2.25rem;
  line-height: normal;
  color: #fff;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background-color 0.3s ease;
  }

  &:hover {
    background: ${colours.pink};
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);

    &::before {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 767px) {
    padding: 12px 30px;
    font-size: 2rem;
  }

  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

const ConnectWallet = styled(WalletDialogButton)`
  &[type="button"] {
    ${ButtonStyles}
    text-transform: uppercase;
  }
`;

const MintButton = styled(Button)`
  &[type="button"] {
    ${ButtonStyles}
  }
`;

const PreSaleImage = styled.div`
  display: flex;
  align-items: center;
  width: 28%;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);

  span {
    display: block;
    position: relative;
  }

  img {
    display: block;
  }

  ${Sparkle}:nth-child(2) {
    width: 23%;
    height: 23%;
    top: 68%;
    left: 74%;
    animation-delay: 0.3s;
  }

  ${Sparkle}:nth-child(3) {
    width: 20%;
    height: 20%;
    top: 52%;
    left: 83%;
    animation-delay: 0.7s;
  }

  @media (max-width: 1300px) {
    width: 34%;
    right: 3%;
  }

  @media (max-width: 990px) {
    order: -1;
    width: 80%;
    max-width: 400px;
    margin: -80px 0 20px 0;
    position: static;
    transform: none;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin-top: -60px;
  }
`;

type Props = {
  wallet: AnchorWallet | undefined;
  isSoldOut: boolean;
  isMinting: boolean;
  isActive: boolean;
  onMint: () => Promise<void>;
  startDate: Date;
  renderCounter: ({
    days,
    hours,
    minutes,
    seconds
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => React.ReactElement;
};

const PreSale = ({
  wallet,
  isSoldOut,
  isMinting,
  isActive,
  onMint,
  startDate,
  renderCounter
}: Props) => {
  return (
    <PreSalePanel as="section" data-aos="fade-up">
      <PreSaleText>
        <h2>Master Edition Sale</h2>

        <p>
          3,962 LIGHT, FIRE, WATER and EARTH Hero NFTs
          <br />
          You will receive one Hero at random
          <br />
          0.5
          <img src={solanaIcon} alt="" width="18" height="18" /> Solana each
          <br />
          Sale starts December 10th 15:00 UTC
        </p>

        {wallet ? (
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "BUY NOW"
              )
            ) : (
              <Countdown date={startDate} renderer={renderCounter} />
            )}
          </MintButton>
        ) : (
          <ConnectWallet type="button">Connect Wallet</ConnectWallet>
        )}
      </PreSaleText>

      <PreSaleImage>
        <span>
          <img src={masterEditionCardPacks} alt="Master edition card packs" />
          <Sparkle />
          <Sparkle />
        </span>
      </PreSaleImage>
    </PreSalePanel>
  );
};

export default PreSale;
