import styled from "styled-components";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Link } from "react-router-dom";
import { Container, ButtonStyles } from "../shared/styled";
import colours from "../shared/colours";

import logo from "../../images/logo.png";

const StyledHeader = styled.header`
  background: ${colours.purple};
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  padding: 30px 0;
  position: relative;

  ${Container} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  width: 130px;
  height: 80px;

  @media (max-width: 480px) {
    width: 100px;
    height: 60px;
  }
`;

const ConnectWallet = styled(WalletDialogButton)`
  &[type="button"] {
    ${ButtonStyles}
    background: ${colours.lightPurple};
    margin-left: 10px;
    font-size: 1.8rem;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 20px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: background-color 0.3s ease;
    }

    &:hover,
    &:focus {
      background: ${colours.lightPurple};
      box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);

      &::before {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    @media (max-width: 480px) {
      font-size: 1.6rem;
    }
  }
`;

type Props = {
  wallet: AnchorWallet | undefined;
};

const Header = ({ wallet }: Props) => {
  return (
    <StyledHeader>
      <Container>
        <Logo to="/">
          <img src={logo} alt="Dragon's Watch Heroes" />
        </Logo>

        {!wallet && <ConnectWallet type="button">Connect Wallet</ConnectWallet>}
      </Container>
    </StyledHeader>
  );
};

export default Header;
