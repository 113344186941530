import styled from "styled-components";
import { Link } from "react-router-dom";
import { Panel, Sparkle, ButtonStyles } from "../shared/styled";

import colours from "../shared/colours";

import cardPacks from "../../images/card-packs.png";
import solanaIcon from "../../images/solana_icon_white.svg";

const GenesisSalePanel = styled(Panel)`
  position: relative;

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const GenesisSaleText = styled.div`
  width: 50%;
  margin-left: auto;
  text-align: center;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}
  background: ${colours.pink};
  padding: 20px 30px;
  font-size: 2.25rem;
  text-align: center;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background-color 0.3s ease;
  }

  &:hover,
  &:focus {
    background: ${colours.pink};
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);

    &::before {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 767px) {
    padding: 12px 30px;
    font-size: 2rem;
  }

  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

const GenesisSaleImage = styled.div`
  display: flex;
  align-items: center;
  width: 28%;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);

  span {
    display: block;
    position: relative;
  }

  img {
    display: block;
  }

  ${Sparkle}:nth-child(2) {
    width: 23%;
    height: 23%;
    top: 68%;
    left: 0;
    animation-delay: 0.3s;
  }

  ${Sparkle}:nth-child(3) {
    width: 20%;
    height: 20%;
    top: 52%;
    left: -2%;
    animation-delay: 0.7s;
  }

  @media (max-width: 1300px) {
    width: 34%;
    left: 3%;
  }

  @media (max-width: 990px) {
    order: -1;
    width: 80%;
    max-width: 400px;
    margin: -80px 0 20px 0;
    position: static;
    transform: none;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin-top: -60px;
  }
`;

const GenesisSale = () => {
  return (
    <GenesisSalePanel as="section" data-aos="fade-up">
      <GenesisSaleText>
        <h2>Genesis Sale</h2>

        <p>
          2,108 DARK Hero NFTs
          <br />
          1.0
          <img src={solanaIcon} alt="" width="18" height="18" /> Solana each
        </p>

        <StyledLink to="/genesis" onClick={() => window.scrollTo(0, 0)}>
          BUY NOW
        </StyledLink>
      </GenesisSaleText>

      <GenesisSaleImage>
        <span>
          <img src={cardPacks} alt="Dark card packs" />
          <Sparkle />
          <Sparkle />
        </span>
      </GenesisSaleImage>
    </GenesisSalePanel>
  );
};

export default GenesisSale;
