import * as React from "react";
import styled from "styled-components";

import { CenteredHeading } from "../shared/styled";
import colours from "../shared/colours";

const FAQArray: { question: string; answer: React.ReactNode }[] = [
  {
    question: "How do I buy a Hero?",
    answer: (
      <p>
        You will need to connect your wallet by clicking the button top right.
        Make sure you have enough funds in your wallet to purchase the hero.
        Then simply click buy.
      </p>
    )
  },
  {
    question: "When is the drop and how much is it?",
    answer: (
      <p>
        The Genesis sale starts is 19th November 2021. The price is 1 Solana.<br />
        The Master Editon sale starts is 10th December 2021. The price is 0.5 Solana.<br />
        The Genesis sale will end when the Master Edition sale starts.
      </p>
    )
  },
  {
    question: "Is there a limit?",
    answer: (
      <p>
        Hero purchases are limited to one per transaction. There’s no limit to
        how many transactions you can do.
      </p>
    )
  },
  {
    question: "Will there be a secondary market?",
    answer: (
      <>
        <p>
          Yes. Once you own the NFT you will be able to trade it on Solana
          marketplaces including:
        </p>
        <ul>
          <li>
            <a
              href="https://ftx.us/nfts/issuer/Dragons%20Watch%20Heroes/25/1"
              target="_blank"
              rel="noreferrer"
            >
              FTX.US
            </a>
          </li>
          <li>
            <a
              href="https://digitaleyes.market/collections/Dragons%20Watch%20Heroes"
              target="_blank"
              rel="noreferrer"
            >
              Digital Eyes
            </a>
          </li>
          <li>
            <a
              href="https://solsea.io/collection/61983551932c5a0b7b42cf4d"
              target="_blank"
              rel="noreferrer"
            >
              SolSea
            </a>
          </li>
          <li>
            <a href="https://magiceden.io/marketplace/dragons_watch_heroes" target="_blank" rel="noreferrer">
              Magic Eden
            </a>
          </li>
        </ul>
      </>
    )
  },
  {
    question: "When will the game come out?",
    answer: (
      <p>
        Game development will commence only after the Genesis and Master Edition sales have sold out. The the alpha build of the
        game should be ready within 6 months of that and a full release hopefully within a year. 
      </p>
    )
  },
  {
    question: "What can I do with my hero?",
    answer: (
      <p>
        The heroes will be used to build your deck of cards. You will play with
        the deck in game. These early edition heroes will give you major
        play-to-earn bonuses. The more cards you buy the bigger the play-to-earn
        rewards.
      </p>
    )
  },
  {
    question: "How many Heroes do I need to play?",
    answer: (
      <p>
        You will need a deck of cards to play, however, you can start playing
        for free. This 1st edition NFT sale isn't about building you deck.
        Instead this is about backing the game development. You will be rewarded
        in-game for believing in the project early on. The first edition is
        super rare and will give you better play-to-earn bonuses. Later editions
        will be minted in increasing quantities to allow new players to easily
        join but the play-to-earn rewards won't be as good.
      </p>
    )
  }
];

const FAQSection = styled.section`
  //overflow: hidden;
`;

const FAQPanel = styled.article`
  background: ${colours.purple};
  border: 3px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.45);
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &[data-open="true"] {
    background: ${colours.lightPurple};
    border-color: ${colours.white};
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
  }

  li {
    margin-bottom: 5px;
  }
`;

const Question = styled.h3`
  padding: 20px;
  margin: 0;
  cursor: pointer;
`;

const Answer = styled.div`
  padding: 0 20px 30px;
`;

const Collapse = styled.div`
  overflow: hidden;
  transition: height 0.3s ease;
`;

const FAQ = ({
  index,
  title,
  isOpen,
  setIsOpen,
  children
}: {
  index: number;
  title: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
  children: React.ReactNode;
}) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height as number);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <FAQPanel data-open={isOpen} data-aos="fade-up">
      <Question
        onClick={() =>
          setIsOpen(curr => {
            const newArray = [...curr].fill(false);
            newArray[index] = !curr[index];
            return newArray;
          })
        }
      >
        {title}
      </Question>

      <Collapse style={{ height: height }}>
        <Answer ref={ref}>{children}</Answer>
      </Collapse>
    </FAQPanel>
  );
};

const FAQs = () => {
  const [isOpen, setIsOpen] = React.useState(FAQArray.map(() => false));

  return (
    <FAQSection>
      <CenteredHeading data-aos="zoom-out">FAQs</CenteredHeading>

      {FAQArray.map((faq, index) => (
        <FAQ
          key={index}
          index={index}
          title={faq.question}
          isOpen={isOpen[index]}
          setIsOpen={setIsOpen}
        >
          {faq.answer}
        </FAQ>
      ))}
    </FAQSection>
  );
};

export default FAQs;
